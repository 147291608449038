import React, { useEffect, useState } from 'react'
import { Menu, Dropdown, Badge, Avatar, List, Button } from 'antd'
import {
  MailOutlined,
  BellOutlined,
  WarningOutlined,
  CheckCircleOutlined,
  UserAddOutlined,
  PlusCircleOutlined,
  EyeOutlined,
  SoundOutlined,
  FolderAddOutlined,
  FolderViewOutlined
} from '@ant-design/icons'
import notificationData from 'assets/data/notification.data.json'
import Flex from 'components/shared-components/Flex'
import notificationService from 'services/notification'
import { APP_PREFIX_PATH } from 'configs/AppConfig'

const getIcon = (icon) => {
  switch (icon) {
    case 'mail':
      return <MailOutlined />
    case 'Sub Admin Created':
      return <UserAddOutlined />
    case 'Participant Created':
      return <PlusCircleOutlined />
    case 'Buyer Created Admin':
      return <PlusCircleOutlined />
    case 'Participant Account Activated':
      return <CheckCircleOutlined />
    case 'Auction Request Created Admin':
      return <FolderViewOutlined />
    case 'Auction Inventory Created':
      return <FolderAddOutlined />
    case 'General':
      return <SoundOutlined />
    // case 'alert':
    //   return <WarningOutlined />
    // case 'check':
    //   return <CheckCircleOutlined />
    default:
      return <MailOutlined />
  }
}
const getType = (icon) => {
  switch (icon) {
    case 'Sub Admin Created':
      return "orange"
    case 'Participant Created':
      return "green"
    case 'Buyer Created Admin':
      return "green"
    case 'Participant Account Activated':
      return "purple"
    case 'Auction Request Created Admin':
      return "red"
    case 'Auction Inventory Created':
      return "geekblue"
    case 'General':
      return "info"
    default:
      return 'info'
  }
}

const getNotificationBody = (list) => {
  return list?.length > 0 ? (
    <List
      size="small"
      itemLayout="horizontal"
      dataSource={list}
      renderItem={(item) => (
        <List.Item className="list-clickable">
          <Flex alignItems="center">
            <div className="pr-3">
              {item.img ? (
                <Avatar src={`/img/avatars/${item.img}`} />
              ) : (
                <Avatar
                  className={`ant-avatar-${getType(item.listingType)}`}
                  icon={getIcon(item.listingType)}
                />
              )}
            </div>
            <div className="mr-3">
              {/* <span className="font-weight-bold text-dark">{item.name} </span> */}
              <span className="text-gray-light">{item?.fcm?.description}</span>
            </div>
            <small className="ml-auto">{new Date(Number(item?.createdAt))?.toLocaleTimeString("en-US", { hour12: true, hour: "2-digit", minute: "2-digit" })}</small>
          </Flex>
        </List.Item>
      )}
    />
  ) : (
    <div className="empty-notification">
      <img
        src="https://gw.alipayobjects.com/zos/rmsportal/sAuJeJzSKbUmHfBQRzmZ.svg"
        alt="empty"
      />
      <p className="mt-3">You have viewed all notifications</p>
    </div>
  )
}

export const NavNotification = () => {
  const [visible, setVisible] = useState(false)
  console.log('visible - ', visible)
  const [data, setData] = useState(notificationData)
  console.log('notification data - ', data)
  const [notifIDs, setNotifIDs] = useState([])
  console.log("notification IDs - ", notifIDs)

  const handleVisibleChange = (flag) => {
    setVisible(flag)
  }

  const getNotifications = async (fetchParams) => {
    const notifData = await notificationService.getNotifications(fetchParams);
    console.log("notifData", notifData)
    if (notifData) {
      setData(notifData);
      setNotifIDs(() => notifData?.data?.map((notif) => notif._id))
    }
  }

  useEffect(() => {
    getNotifications(`read=false`);
  }, [])

  const onClear = async () => {
    const data = await notificationService.readData({ notificationHistoryIds: notifIDs });
    if (data?.success) {
      setData([])
      // getNotifications(`read=false`)
    }
  }


  const notificationList = (
    <div className="nav-dropdown nav-notification">
      <div className="nav-notification-header d-flex justify-content-between align-items-center">
        <h4 className="mb-0">Notification</h4>
        <Button
          className="text-primary"
          type="text"
          onClick={onClear}
          size="small"
        >
          Clear{' '}
        </Button>
      </div>
      <div className="nav-notification-body">
        {getNotificationBody(data?.data)}
      </div>
      {data?.data?.length > 0 ? (
        <div className="nav-notification-footer">
          <a className="d-block" href={`${APP_PREFIX_PATH}/dashboards/notifications`} >
            View all
          </a>
        </div>
      ) : null}
    </div>
  )

  return (
    <Dropdown
      placement="bottomRight"
      overlay={notificationList}
      onVisibleChange={handleVisibleChange}
      visible={visible}
      trigger={['click']}
    >
      <Menu mode="horizontal">
        <Menu.Item key="notification">
          <Badge count={data.unReadCount}>
            <BellOutlined className="nav-icon mx-auto" type="bell" />
          </Badge>
        </Menu.Item>
      </Menu>
    </Dropdown>
  )
}

export default NavNotification
