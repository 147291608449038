import fetch from 'auth/FetchInterceptor';

const notificationService = {};
const api = '/notification';

notificationService.getNotifications = async function (query = '') {
    try {
        const res = await fetch({
            url: `${api}/get/all?${query}`,
            method: 'get'
        });
        return res;
    } catch (err) {
        console.log(err, 'show-err');
    }
};
notificationService.getNotifications2 = async function (query = '') {
    try {
        const res = await fetch({
            url: `${api}/get/all${query}`,
            method: 'get'
        });
        return res;
    } catch (err) {
        console.log(err, 'show-err');
    }
};

notificationService.readData = async function (data, query = '') {
    try {
        const res = await fetch({
            url: `${api}/read?${query}`,
            method: 'put',
            data: data
        });
        return res;
    } catch (err) {
        console.log(err, 'show-err');
    }
};

export default notificationService;